<template>
  <div class="position-relative map-view">
    <div id="alert-map" class="w-100 h-100"></div>
    <div class="map-floating-button" v-if="showMapButton">
      <button
        class="btn shadow-sm"
        :class="isSatelliteView ? 'btn-success' : 'btn-dark text-default-dark'"
        data-toggle="tooltip"
        title="Toggle satellite view"
        @mouseenter="Helper.showTooltip($event)"
        @click="isSatelliteView = !isSatelliteView"
      >
        <i class="fas fa-satellite"></i>
      </button>
    </div>
    <div
      ref="alertSidebar"
      class="alert-sidebar"
      :class="{ hide: listExpanded }"
      v-if="alerts.length"
    >
      <template v-if="currentAlert">
        <div
          class="bg-dark text-uppercase px-3 py-2 font-weight-bold d-flex justify-content-between align-items-center sticky-top"
        >
          <span>
            <i class="fas fa-calendar-alt text-default-dark mr-2" />
            {{ Helper.formatDate(currentAlert.createdAt, true) }}
          </span>
          <button
            class="btn btn-link text-light p-0"
            @click="currentAlert = null"
          >
            <i class="fas fa-times" />
          </button>
        </div>
        <div class="pt-3">
          <img
            :src="Helper.formatMediaUrl(currentAlert.media)"
            v-if="currentAlert.media"
            class="w-100 mb-2"
          />
          <a
            v-if="currentAlert.lat && currentAlert.lng"
            :href="`https://www.google.com/maps/search/?api=1&query=${currentAlert.lat},${currentAlert.lng}`"
            target="_blank"
            class="btn btn-primary d-flex justify-content-between align-items-center w-100 mb-2"
          >
            <span>
              <i class="fas fa-map-pin mr-2" />
              <span>
                {{
                  Helper.formatCoordinate([currentAlert.lat, currentAlert.lng])
                }}
              </span>
            </span>
            <i class="fas fa-caret-right ml-2" />
          </a>
          <div class="bg-default-dark p-2 mb-2">
            <div class="pb-2">{{ currentAlert.description }}</div>
            <template v-for="(category, index) in currentAlert.categories">
              <span
                class="badge badge-pill badge-secondary py-2 px-3 font-weight-normal ml-1 my-1"
                style="font-size: 0.9rem"
              >
                <img
                  :src="categoryIcon(category)"
                  class="mr-2"
                  style="width: 15px; height: 15px"
                />
                {{ category }}
              </span>
            </template>
          </div>
          <div class="bg-default-dark p-2">
            <p class="px-2 text-uppercase">
              <i class="fas fa-user-circle mr-2" />
              {{ currentAlert.user.username }}
            </p>
            <p class="px-2">
              <i class="fas fa-mobile-alt mr-2" />
              {{ currentAlert.user.phone }}
            </p>
            <p class="px-2 mb-0">
              <i class="fas fa-envelope mr-2" />
              {{ currentAlert.user.email }}
            </p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex justify-content-end align-items-center pb-1">
          <div class="font-weight-bold pl-1 mr-auto" v-if="listExpanded">
            ALERT LIST
          </div>
          <button
            class="btn btn-dark shadow-sm"
            data-toggle="tooltip"
            data-boundary="viewport"
            title="Toggle alert list"
            @mouseenter="Helper.showTooltip($event)"
            @click="listExpanded = !listExpanded"
          >
            <i class="fas fa-times text-light" v-if="!listExpanded"></i>
            <i class="fas fa-exclamation-triangle text-warning" v-else></i>
          </button>
        </div>
        <button
          v-for="(alert, index) in alerts"
          class="btn btn-dark p-3 mb-2 text-left w-100"
          :class="{ 'mb-2': index }"
          @click="currentAlert = alert"
        >
          <p class="text-muted font-weight-bold mb-1">
            {{ Helper.formatDate(alert.createdAt, true) }}
          </p>
          <p
            class="lead text-default-dark font-weight-bold text-uppercase mb-1"
          >
            {{ alert.user.username }}
          </p>
          <p class="text-3-row mb-2 overflow-hidden text-muted">
            {{ alert.description }}
          </p>
          <template v-if="alert.categories && alert.categories.length > 0">
            <span
              class="badge badge-pill badge-secondary py-2 px-3 font-weight-normal"
              style="font-size: 0.9rem"
            >
              <img
                :src="categoryIcon(alert.categories[0])"
                class="mr-2"
                style="width: 15px; height: 15px"
              />
              {{ alert.categories[0] }}
            </span>
            <span
              v-if="alert.categories.length > 1"
              class="badge badge-pill badge-secondary ml-2 p-2 font-weight-normal"
            >
              + {{ alert.categories.length - 1 }}
            </span>
          </template>
        </button>
      </template>
    </div>
    <spinner :show="isLoading" />
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import Spinner from "@/components/Spinner.vue";
import Swal from "sweetalert2";

export default {
  name: "view-map",
  props: ["hideSidebar"],
  components: {
    Spinner,
  },
  data() {
    return {
      isLoading: false,
      showMapButton: false,
      isSatelliteView: false,
      alerts: [],
      map: null,
      categories: [],
      currentAlert: null,
      listExpanded: false,
    };
  },
  watch: {
    listExpanded: {
      handler() {
        this.$refs.alertSidebar?.scrollTo(0, 0);
      },
      immediate: true,
      deep: true,
    },
    hideSidebar: {
      handler() {
        if (this.map) {
          setTimeout(() => {
            this.map.resize();
          }, 300);
        }
      },
      immediate: true,
      deep: true,
    },
    isSatelliteView: {
      handler() {
        if (this.map) {
          let styleUrl = this.Helper.mapStyle(this.isSatelliteView);

          this.map.setStyle(styleUrl);

          setTimeout(async () => {
            await this.loadAllMapImages();

            this.setMarkers(this.alerts);
          }, 500);
        }
      },
      immediate: false,
    },
  },
  methods: {
    categoryIcon(name) {
      const category = this.categories.find(
        (cat) => cat.name.toLowerCase() == name.toLowerCase()
      );

      if (!name || !category || !category.icon) {
        return require("@/assets/icons/alert.png");
      } else {
        return this.Helper.formatMediaUrl(category.icon);
      }
    },
    clearMap() {
      if (this.map) {
        this.map.remove();

        this.map = null;
      }
    },
    loadMapImage(icon, name) {
      return new Promise((resolve) => {
        this.map.loadImage(icon, async (err, image) => {
          if (err) {
            console.error(err);

            resolve();

            return;
          }

          this.map.addImage(name, image);

          resolve();
        });
      });
    },
    loadAllMapImages() {
      return new Promise(async (resolve) => {
        await this.loadMapImage(
          require("@/assets/icons/help-general.png"),
          "help-general"
        );

        await this.loadMapImage(
          require("@/assets/icons/cluster.png"),
          "cluster"
        );

        resolve();
      });
    },
    initMap() {
      return new Promise((resolve) => {
        this.clearMap();

        setTimeout(() => {
          this.isLoading = true;

          mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;

          this.map = new mapboxgl.Map({
            container: "alert-map",
            style: this.Helper.mapStyle(),
            center: [101.5179483, 3.075603444131706],
            zoom: 8,
          });

          this.map.on("load", async () => {
            await this.getCategories();

            await this.loadAllMapImages();

            await this.getAlerts();

            this.showMapButton = true;

            this.isLoading = false;

            resolve();
          });
        }, 500);
      });
    },
    setMapBounds(alerts) {
      if (alerts.length == 1) {
        this.map.setCenter([alerts[0].lng, alerts[0].lat]);
      } else if (alerts.length > 1) {
        let bounds = new mapboxgl.LngLatBounds();

        alerts.forEach(function (alert) {
          bounds.extend([alert.lng, alert.lat]);
        });

        this.map.fitBounds(bounds, {
          padding: 150,
          duration: 0,
        });
      }
    },
    clearMarkers() {
      if (this.map) {
        const layers = this.map
          .getStyle()
          .layers.filter((layer) => layer.id.includes("help-layer"));

        const sources = Object.keys(this.map.getStyle().sources).filter(
          (source) => source.includes("help-source")
        );

        if (layers && layers.length > 0) {
          layers.forEach((layer) => {
            this.map.removeLayer(layer.id);
          });
        }

        if (sources && sources.length > 0) {
          sources.forEach((source) => {
            this.map.removeSource(source);
          });
        }
      }
    },
    async setMarkers(alerts) {
      return new Promise((resolve) => {
        this.clearMarkers();

        setTimeout(() => {
          if (alerts && alerts.length > 0) {
            this.map.addSource("help-source", {
              type: "geojson",
              cluster: true,
              data: {
                type: "FeatureCollection",
                features: alerts.map((alert) => {
                  return {
                    type: "Feature",
                    properties: {
                      id: alert.id,
                    },
                    geometry: {
                      type: "Point",
                      coordinates: [alert.lng, alert.lat],
                    },
                  };
                }),
              },
            });

            this.map.addLayer({
              id: "help-layer-cluster",
              type: "symbol",
              source: "help-source",
              filter: ["has", "point_count"],
              layout: {
                "icon-image": "cluster",
                "icon-size": 1,
                "text-field": "{point_count_abbreviated}",
                "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                "text-size": 14,
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
              },
              paint: {
                "text-color": "#000000",
              },
            });

            this.map.addLayer({
              id: "help-layer-marker",
              type: "symbol",
              source: "help-source",
              filter: ["!", ["has", "point_count"]],
              layout: {
                "icon-image": "help-general",
                "icon-size": 0.8,
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
              },
            });

            this.map.on(
              "click",
              "help-layer-cluster",
              this.mapClusterClickEvent
            );

            this.map.on("click", "help-layer-marker", this.mapMarkerClickEvent);

            this.map.on(
              "mouseenter",
              "help-layer-cluster",
              this.mapMouseCursorPointer
            );

            this.map.on(
              "mouseleave",
              "help-layer-cluster",
              this.mapMouseCursorDefault
            );

            this.map.on(
              "mouseenter",
              "help-layer-marker",
              this.mapMouseCursorPointer
            );

            this.map.on(
              "mouseleave",
              "help-layer-marker",
              this.mapMouseCursorDefault
            );
          }

          resolve();
        }, 500);
      });
    },
    mapMarkerClickEvent(e) {
      const alert = this.alerts.find(
        (alert) => alert.id == e.features[0].properties.id
      );

      if (!alert) {
        Swal.fire({
          title: "<h5 class='mb-0'>Invalid alert info</h5>",
          icon: "error",
        });
      } else {
        this.currentAlert = alert;
      }
    },
    mapClusterClickEvent(e) {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ["help-layer-cluster"],
      });

      const clusterId = features[0].properties.cluster_id;

      this.map
        .getSource("help-source")
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          this.map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
    },
    mapMouseCursorPointer() {
      this.map.getCanvas().style.cursor = "pointer";
    },
    mapMouseCursorDefault() {
      this.map.getCanvas().style.cursor = "";
    },
    async getCategories() {
      const [categoriesCall, categoriesCallErr] = await this.Helper.handle(
        this.API.get("alert-categories")
      );

      if (!categoriesCallErr && categoriesCall.status == 200) {
        this.categories = categoriesCall.data;
      }
    },
    async getAlerts() {
      const [alertCall, alertCallErr] = await this.Helper.handle(
        this.API.get("alerts?isDeleted=false")
      );

      if (!alertCallErr && alertCall.status == 200) {
        this.alerts = alertCall.data.map((alert) => {
          return {
            id: alert.id,
            user: alert["users_permissions_user"] || {},
            lat: alert.lat,
            lng: alert.lng,
            description: alert.description,
            extraInfo: alert.extraInfo,
            categories: alert.categories,
            media:
              alert.media && alert.media.length > 0 ? alert.media[0] : null,
            createdAt: alert.createdAt,
          };
        });

        await this.setMarkers(alertCall.data);

        this.setMapBounds(alertCall.data);
      }
    },
  },
  mounted() {
    this.initMap();
  },
  beforeDestroy() {
    this.clearMap();
  },
};
</script>